import React, { Fragment, useState } from "react";
import styled from "@emotion/styled";
import Customdatepicker from "../DatePicker/Customdatepicker";
import styles from "./ImagingMultiCenterCalendar.module.css";
import { setMultiCenterTabCenterId } from "../../../ReduxStore/action";
import { useSelector, useDispatch } from "react-redux";

let center_options = [
    {
        center_id: 1,
        center_location: "Parramatta",
    },
    {
        center_id: 3,
        center_location: "Westfield",
    },
    {
        center_id: 4,
        center_location: "Toongabbie",
    },
    {
        center_id: 100,
        center_location: "All Centers",
    },
];
// “currently_selected_center_id”:100,

const Container = styled.div`
  width: 100%;
  ${"" /* display:flex; */}
  align-items:center;
  justify-content: center;
  ${"" /* border:1px solid; */}
`;
const TabContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${"" /* justify-content:center; */}
  ${"" /* border:1px solid; */}
margin-bottom:5px;
overflow-x: scroll; 
  overflow-y: hidden; 
`;
const CalendarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomTabContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 2px;
  font-size: 12px;
`;

const LoadingContainer = styled.div`
width:100%;
justify-content:center;
 align-items:center;
height:300px;
  border:1px solid;
`



const CustomTab = ({ data, onClickHandler, selectedTabId }) => {
    return (
        <div
            className={selectedTabId === data?.center_id ? `${styles.vengage__selectedTab} ${styles.vengage__customtab}` : styles.vengage__customtab}
            onClick={() => onClickHandler(data)}
        >
            {data?.center_location}
        </div>
    );
};

const ImagingMultiCenterCalendar = (props) => {
    const { staticData } = useSelector((state) => state.Reducer);

    const dispatch = useDispatch()
    // console.log(
    //   "🚀 ~ file: ImagingMultiCenterCalendar.js:63 ~ ImagingMultiCenterCalendar ~ props:",
    //   props
    // );
    const [tabOptions, setTabOptions] = useState(
        props?.multi_center_tab_options ? props?.multi_center_tab_options : []
    );
    const [workingDays, setWorkingDays] = useState(props?.workingDays);
    const [selectedTabId, setSelectedTabId] = useState(
        props?.selected_tab_id ? props?.selected_tab_id : null
    );


    const [isMonthDifferent, setIsMonthDifferent] = useState(true) // no use
    const [centerList, setCenterList] = useState([]) // no use
    const [disableTabData, setDisableData] = useState(false)




    const [loadingCalendar, setLoadingCalendar] = useState(false);
    console.log("🚀 ~ file: ImagingMultiCenterCalendar.js:92 ~ ImagingMultiCenterCalendar ~ loadingCalendar:", loadingCalendar)

    const handleAIResponse = (response) => {
        let ai_response = JSON.parse(response.data);
        console.log(
            "🚀 ~ file: ImagingMultiCenterCalendar.js:71 ~ handleAIResponse ~ ai_response:",
            ai_response
        );
        if (ai_response.working_days && ai_response.working_days.length > 0) {
            let month1 = ai_response.working_days[0].date
            month1 = month1.split('-')
            let month2 = ai_response.working_days[ai_response.working_days.length - 1].date
            month2 = month2.split("-")
            if (Number(month2[1]) != Number(month1[1])) {
                setIsMonthDifferent(true)
            } else {
                setIsMonthDifferent(false)
            }


        }


        setWorkingDays(ai_response?.working_days);
        setSelectedTabId(ai_response?.currently_selected_center_id);
        setLoadingCalendar(false);

        dispatch(setMultiCenterTabCenterId({ center_id: ai_response?.currently_selected_center_id, dummy_center_id: ai_response?.dummy_center_id }))

    };

    const tabSelectionHandler = (data) => {
        setSelectedTabId(data?.center_id);
        // “event”: “MULTIPLE_CENTER_SELECTION”,
        // “uid”: “f7dc8336-fbdf-4d8a-9068-90e1cb1f5029",
        // “data”: “All Centers”,
        // “center_id”: 100,
        // “message_id”: “98a77ad1-676b-4521-adc6-3598de7038f6”

        console.log("TAB CHNAGED", data)

        let message = {
            event: "MULTIPLE_CENTER_SELECTION",
            uid: props.conversationId,
            data: data?.center_location,
            center_id: data?.center_id,
        };
        props.wsClient.handleUserResponse(message, handleAIResponse);

        setLoadingCalendar(true);

    };
  const delayFetchingCalenderText=staticData?.delay_in_fetching_calendar_data ? <span>{staticData?.delay_in_fetching_calendar_data}</span>  :"This may take upto 30 seconds" ;
    return (
        <Fragment>
            <div className={styles.vengage__showDatePickerWithTab} style={{ display: disableTabData && "none" }}>


                <div className={styles.vengage__tabs}>
                    {tabOptions?.map((each, index) => (
                        <CustomTab
                            selectedTabId={selectedTabId}
                            data={each}
                            key={index}
                            onClickHandler={(data) => tabSelectionHandler(data)}
                        />
                    ))}
                </div>

                <div className={!isMonthDifferent ? styles.vengage__datePicker__oneMonth : styles.vengage__datePicker} style={{ borderTopLeftRadius: centerList && centerList.length === 1 && "8px,", height: centerList && centerList.length > 1 && "395px" }}  >
                    {loadingCalendar ? <div style={{ display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center", height: "337px" }}>
                        <p className={styles.vengage__dot_pulse}></p><div style={{ fontStyle:"italic", fontSize:"10px" }}><p>Please wait while we fetch the available dates</p> </div> </div>
                        :

                        <Customdatepicker setDisableData={setDisableData} {...props} workingDays={workingDays} center_id={selectedTabId} />
                    } 
                </div>


            </div>
        </Fragment>
    );
};

export default ImagingMultiCenterCalendar;
