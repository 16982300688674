import React, { useEffect, useState } from "react";
import CustomButton from "../../../UI/CustomButton/CustomButton";

import styles from "./index.module.css";
import { useSelector} from "react-redux";
import parse from "html-react-parser";

function Calendar(props) {
  console.log("🚀 ~ file: index.jsx:9 ~ Calendar ~ props:", props)
  const {iswebSocketConnected,iswebSocketTryingToReConnect} = useSelector(state=>state.Reducer)

  const [selectedSlot, setSelectedSlot] = useState(null);
  const [showSlot, setShowSlot] = useState("");

  const [disableButton, setDisableButton] = useState(true);
  //NOTE:- converting 24 hr to 12 hr
  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  /* NOTE:- after selecting the slot , message comes through this webSocketMessage and then we show that message in chatBot via showResponseDialog method
   which is written in action provider component  */

  const handleAIResponse = (webSocketMessage) => {
    let ai_response = JSON.parse(webSocketMessage.data);
    // console.log("ai_response Calender component", ai_response);
    //here type is TEXT , now in actionProvider component when case is TEXT then showResponseDialog method will show the AI response
    props.actionProvider.showResponseDialog(ai_response);
  };
  const handleAIResponseSSelectDay = (webSocketMessage) => {
    let ai_response = JSON.parse(webSocketMessage.data);
    // if (ai_response.type === "PRESET") {

    // }
    // console.log("DAY_LIST", ai_response);
    // ai_response.type = "DAY_LIST";
    // console.log("ai_response Calender component", ai_response);
    //here type is TEXT , now in actionProvider component when case is TEXT then showResponseDialog method will show the AI response
    props.actionProvider.showResponseDialog(ai_response);
  };

  const handleBtnEvent = (option, index) => {
    // NOTE:- this function will only run when we select any one slot from given slot time

    // NOTE:- due this commented  event was not going to the socket
    // if (selectedSlot != null && selectedSlot != "")

    // NOTE:- this if tells that want to choose anoter day for appointment
    if (index == 1) {
      props.actionProvider.showResponseDialog({
        type: "USER_TEXT",
        message: option.text,
      });
        let message = ""

        if(option.event==="MULTIPLE_CENTER_SELECTION"){
          message = {
            event: option.event,
            uid: props.conversationId,
            data: option.text,
            slot_time: selectedSlot,
            query_date:props?.Date,
            center_id:props?.center_id
          };

        }else{
           message = {
            event: option.event,
            uid: props.conversationId,
            data: option.text,
            slot_time: selectedSlot,
          };
        }

       


        props.wsClient?.handleUserResponse(message, handleAIResponseSSelectDay);
        
    }

    // NOTE:- this is tells that you have selected a slot
    if (selectedSlot != null && selectedSlot != "" && index == 0) {
      let appointmentTime = selectedSlot.split(" ");

      let monthNDate = new Date(appointmentTime[0]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });
      let MonthNDate = monthNDate.split(" ");

      let Time = tConvert(appointmentTime[1]);
      let date = appointmentTime[0].split("-");

      // console.log("TIME ****", Time);
      props.actionProvider.showResponseDialog({
        type: "USER_TEXT",
        message: `${MonthNDate[1]}-${MonthNDate[0]}-${date[0]}   ${Time}`,
      });

      let message =""



      


       
         if(option?.event==="CONFIRM_CENTER_AND_SLOT_SELECTION_FOR_IMAGING_MULTI_CENTER_WORKFLOW"){
           message = {
            event: option.event,
            uid: props.conversationId,
            data: option.text,
            slot_time: selectedSlot,
            query_date:props?.Date,
            center_id:props?.center_id
          };



         }else{
          message = {
            event: option.event,
            uid: props.conversationId,
            data: option.text,
            slot_time: selectedSlot,
          };
         }
       
   

      
        props.wsClient?.handleUserResponse(message,handleAIResponse );

       
    }


    setShowSlot("none");
    if(props.setDisableData){

      props.setDisableData(true)
    }
  };

  const handleSlotSelection = (slot_time) => {
    setDisableButton(false);
    setSelectedSlot(slot_time);
  };

  return (
    <>
    {props.availableSlots.cost_response && <div  className={styles.vengage_cost_item} style={{margin:"3px",textAlign:"left"}} ><b>Note:</b> {parse(props.availableSlots.cost_response)}</div>}
    
    <div className={styles.vengage__options} style={{ display: showSlot }}>
      <div className={styles.vengage__calendar__block}>
        {props.availableSlots !== "" &&
          props.availableSlots.slots_responses.map((option, key) => {
            return (
              <div
                //NOTE :- if   selectedSlot == option.slot_time the make a css of option-item active otherwise option-item
                className={styles.vengage__option__item}
                style={{
                  background:
                    selectedSlot == option.slot_scheduletime && "yellow",
                }}
                key={key}
                onClick={(e) => {
                  handleSlotSelection(option.slot_scheduletime);
                }}
              >
                {tConvert(option.slot_time)}
              </div>
            );
          })}
      </div>
      <center>
        <div
          className={styles.vengage__options__container}
          style={{ width: "80%" }}
        >
          {props.availableSlots !== "" &&
            props.availableSlots.preset_responses.map((option, key) => {
              if (key == 0) {
                return (
                  <CustomButton
                    key={key}
                    text={option.text}
                    disabled={iswebSocketTryingToReConnect?iswebSocketTryingToReConnect:(disableButton?disableButton:props.disableAllButton)}
                    clickHandler={(e) => handleBtnEvent(option, key)}
                  />
                );
              }
              if (key == 1) {
                return (
                  <CustomButton
                    key={key}
                    text={option.text}
                    clickHandler={(e) => handleBtnEvent(option, key)}
                    disabled={iswebSocketTryingToReConnect?iswebSocketTryingToReConnect:(props.disableAllButton)}
                  />
                );
              }
            })}
        </div>
      </center>
    </div>
    </>
  );
}

export default Calendar;
