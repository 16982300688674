import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { doctorIdFunc } from "../../../ReduxStore/action";
import parse from "html-react-parser";

import Datelist from "./Datelist";
import styles from "./styles.module.css";
import { date, workingDays1, workingDays2, workingDays3 } from './DateDataForTesting'
import moment from "moment";
import CustomButton from "../../../UI/CustomButton/CustomButton";
const Customdatepicker = (props) => {
    const State = useSelector((state) => state.Reducer);
    const { activeButtonColor, activeButtonTextColor, inactiveButtonColor, inactiveButtonTextColor } = useSelector((state) => state.Theme);
    const [confirmationText, setConfirmationText] = useState("")
    useEffect(() => {
        setConfirmationText(props.confirmatrionText)
    }, [])
    const dispatch = useDispatch();
    const [monthLastDayIndex, setMonthLastDayIndex] = useState("");
    const [monthFirstDayIndex, setMonthFirstDayIndex] = useState("");
    const [showSelectedUserDate, setShowSelectedUserDate] = useState("");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    let readyState = null


    const [selectedDate, setSelectedDate] = useState("");
    const [disableButton, setDisableButton] = useState(true);
    const [showDaytable, setShowDaytable] = useState("");

    const [dateToggle, setDateToggle] = useState({
        fistDate: false,
        secondDate: false,
    });

    useEffect(() => {
        if (props.scrollIntoView) {

            props.scrollIntoView();
        }
    });

    //************************************************************ */
    const Months = [
        { label: "January", value: 1, shortName: "Jan" },
        { label: "February", value: 2, shortName: "Feb" },
        { label: "March", value: 3, shortName: "Mar" },
        { label: "April", value: 4, shortName: "Apr" },
        { label: "May", value: 5, shortName: "May" },
        { label: "June", value: 6, shortName: "Jun" },
        { label: "July", value: 7, shortName: "July" },
        { label: "August", value: 8, shortName: "Aug" },
        { label: "September", value: 9, shortName: "Sep" },
        { label: "October", value: 10, shortName: "Oct" },
        { label: "November", value: 11, shortName: "Nov" },
        { label: "December", value: 12, shortName: "Dec" },
    ];
    const WeekDays = [
        { label: "Sunday", shortName: "Sun" },
        { label: "Monday", shortName: "Mon" },
        { label: "Tuesday", shortName: "Tue" },
        { label: "Wednesday", shortName: "Wed" },
        { label: "Thursday", shortName: "Thu" },
        { label: "Friday", shortName: "Fri" },
        { label: "Saturday", shortName: "Sat" },
    ];
    //************************************* */

    //********************************************** */
    //Set date and Month
    const [monthArray, setMonthArray] = useState([]);
    const [yearArray, setYearArray] = useState([]);
    const [dateListOne, setDateListOne] = useState([]);
    const [dateListTwo, setDateListTwo] = useState([]);
    const [finalDateList, setFinalDateList] = useState([]);
    // console.log("yearArray", yearArray);



    //NOTE:- Spliting the date list in to the two date list
    useEffect(() => {
        let consumedMonthsIndexList = [];
        let consumedYearList = [];
        for (let i = 0; i < props?.workingDays?.length; i++) {
            let _calendarDate = moment(props.workingDays[i].date, "YYYY-MM-DD");

            if (consumedYearList.includes(_calendarDate.year()) == false) {
                consumedYearList.push(_calendarDate.year())
            }
            if (consumedMonthsIndexList.includes(_calendarDate.month()) == false && consumedMonthsIndexList.length < 2) {
                consumedMonthsIndexList.push(_calendarDate.month())
            }
            if (consumedMonthsIndexList.length == 1) {
                setDateListOne((prevState) => [...prevState, props.workingDays[i]]);
            }
            else if (consumedMonthsIndexList.length == 2 && _calendarDate.month() === consumedMonthsIndexList[consumedMonthsIndexList.length - 1]) {
                setDateListTwo((prevState) => [...prevState, props.workingDays[i]]);
            }
            else {
                // do nothing
            }

        }
        setMonthArray(consumedMonthsIndexList)
        setYearArray(consumedYearList)
    }, []);

    //*************************************************************** */
    // finding the days in a month
    var days = (month, year) => {
        return new Date(year, month, 0).getDate();
    };

    //**************************************************************** */
    const handleAIResponse = (webSocketMessage) => {
        let ai_response = JSON.parse(webSocketMessage.data);
        console.log("AI_RESPONSE_CUSTOME_DATE_PICKER", ai_response)
        props.actionProvider.showResponseDialog(ai_response);
    };

    // NOTE:- Submit Handler function
    const submitHandler = () => {
        if (!selectedDate) {
            return;
        }
        setShowDaytable("none");
        // console.log("Date_showSelectedUserDate", showSelectedUserDate)
        let date = showSelectedUserDate.split("-");

        // console.log("Date", date)
        let monthNDate = new Date(
            `${date[2]}-${date[1]}-${date[0]}`
        ).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
        });
        // console.log("monthNDate", monthNDate, MonthNDate, )

        let MonthNDate = monthNDate.split(" ");

        // console.log("monthNDate", monthNDate, MonthNDate, )

        // Displaying selected date in conversation
        props.actionProvider.showResponseDialog({
            type: "USER_TEXT",
            message: ` ${date[0]}-${monthNames[date[1] - 1]}-${date[2]}`,
        });

        if (props.setDisableData) {
            props.setDisableData(true)
        }


        let SelectedDate = selectedDate.split("-");
        // console.log("SelectedDate", SelectedDate);
        let month = parseInt(SelectedDate[1] - 1);
        let message = "";

        if (props.workflowType == "GP_APPT") {

            message = {
                event: "FETCH_GP_AVAILABLE_SLOTS",
                uid: props.conversationId,
                date: selectedDate,
                doctor_pms_id: State.doctorId,

            };
            if (props.center_id) {
                message["center_id"] = props.center_id
            }


        }
        else if (props.workflowType == "IMAGING_APPT") {
            message = {
                event: "FETCH_IMAGING_AVAILABLE_SLOTS",
                uid: props.conversationId,
                conversation_id: props.conversationId,
                query_date: selectedDate,
                center_id: props.center_id
            };
        }
        else {

            if (props?.event_to_be_returned_by_UI) {
                //       "center_id":1,
                // "query_date":"2023-03-22",
                // "uid": "45b206d3-d353-4e75-ab11-2161b8cf3db6",
                // "message_id": "872f522b-e6e6-44e1-9e5b-d36bfcae21ed"
                message = {
                    event: props?.event_to_be_returned_by_UI,
                    uid: props.conversationId,
                    query_date: selectedDate,
                    center_id: State?.centerTabInfoForMultiCenter?.length ? State?.centerTabInfoForMultiCenter[0].center_id : props?.center_id
                };
            } else {
                message = {
                    event: "USER_TEXT",
                    uid: props.conversationId,
                    data: `${SelectedDate[2]} ${Months[month].label}`,
                };
            }

        }

        // return 
        props.wsClient?.handleUserResponse(message, handleAIResponse);
        // dispatch(doctorIdFunc(""));

    };

    //************************************************************8 */

    //******************************************* */
    useEffect(() => {
        // console.log("Date lis changed");
    }, [finalDateList]);

    useEffect(() => {
        // console.log("Month Array...");
        if (monthArray.length == 1) {
            setFinalDateList(dateListOne);
        } else {
            setFinalDateList(dateListOne);
            setFinalDateList(dateListTwo);
        }
    }, [monthArray]);

    const handleContinue = () => {
        // Displaying selected date in conversation
        setShowDaytable("none");
        if (props.setDisableData) {
            props.setDisableData(true)
        }
        props.actionProvider.showResponseDialog({
            type: "USER_TEXT",
            message: `Request a callback`,
        });

        let message = {
            event: "CONVERT_TO_PROVISIONAL_APPOINTMENT",
            uid: props.conversationId,
            conversation_id: props.conversationId,
            center_id: props.center_id
        };
        props.wsClient?.handleUserResponse(message, handleAIResponse);
    }
    return (
        <div
            className={monthArray.length == 1 ? styles.vengage__customdatepicker__oneMonth : styles.vengage__customdatepicker}
            style={{ display: showDaytable, height: props.centerList && props.centerList.length > 1 && "385px" }}
        >
            {props.calendarMsg && <div style={{ margin: "auto", width: "95%", display: "flex", flexDirection: "column", minHeight: "100%" }}><div style={{ fontSize: "13px", marginTop: "2px", marginLeft: "5px", marginBottom: "5px", flexGrow: 1 }}>{parse(props.calendarMsg)}</div><div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}><CustomButton text="Continue" clickHandler={handleContinue}></CustomButton></div></div>}
            <div className={styles.vengage__customCalender__dateCalendar}>
                {dateListOne.length != 0 && (
                    <div style={{ marginLeft: '10px' }}>
                        <Datelist
                            datelist={dateListOne}
                            month={monthArray[0]}
                            year={yearArray[0]}
                            setSelectedDate={(val) => setSelectedDate(val)}
                            selectedDate={selectedDate}
                            setDateToggle={(val) => setDateToggle(val)}
                            dateToggle={dateToggle.fistDate}
                            list={1}
                            WeekDays={WeekDays}
                            setDisableButton={(val) => setDisableButton(val)}
                            MonthList={Months}
                            monthArray={monthArray}
                            setMonthLastDayIndex={(val) => setMonthLastDayIndex(val)}
                            setMonthFirstDayIndex={(val) => setMonthFirstDayIndex(val)}
                            monthFirstDayIndex={monthFirstDayIndex}
                            setShowSelectedUserDate={(val) => setShowSelectedUserDate(val)}
                        />
                    </div>
                )}
            </div>

            {dateListTwo.length != 0 && (
                <div
                    className={styles.vengage__customCalender__dateCalendar}
                    style={{ marginBottom: "5px" }}
                >
                    {monthArray.length == 2 && (
                        <div style={{ marginTop: "15px", marginLeft: '10px' }}>
                            <Datelist
                                datelist={dateListTwo}
                                month={monthArray[1]}
                                year={yearArray.length == 2 ? yearArray[1] : yearArray[0]}
                                setSelectedDate={(val) => setSelectedDate(val)}
                                selectedDate={selectedDate}
                                setDateToggle={(val) => setDateToggle(val)}
                                dateToggle={dateToggle.secondDate}
                                list={2}
                                WeekDays={WeekDays}
                                setDisableButton={(val) => setDisableButton(val)}
                                MonthList={Months}
                                monthLastDayIndex={monthLastDayIndex}
                                monthFirstDayIndex={monthFirstDayIndex}
                                setShowSelectedUserDate={(val) => setShowSelectedUserDate(val)}
                            />
                        </div>
                    )}
                </div>
            )}

            <center
                className={
                    props.workflowType === "GP_APPT"
                        ? "customCalendar__button_GP"
                        : "customCalendar__button"
                }
            >
                {confirmationText && <button
                    className={styles.vengage__customCalendar__customButton}
                    style={{
                        backgroundColor: State.connectionStatus == "" ? (selectedDate ? (activeButtonColor != null ? activeButtonColor : null) : inactiveButtonColor) : inactiveButtonColor,
                        color: State.connectionStatus == "" ? (selectedDate ? (activeButtonTextColor != null ? activeButtonTextColor : null) : inactiveButtonTextColor) : inactiveButtonTextColor,
                        marginTop: "10px",
                        marginBottom: "10px"
                    }}
                    onClick={submitHandler}
                    disabled={State.connectionStatus != "" ? true : props.disableAllButton}
                >
                    {confirmationText}
                </button>
                }
            </center>
        </div>
    );
};

export default Customdatepicker;
